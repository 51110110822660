import { useState } from "react";
import { useAuth } from "../firebase/auth.tsx";
import CreateHQModal from "./modals/createHQModal.tsx";
import CreateGroupModal from "./modals/createGroupModal.tsx";
import CreateAccountModal from "./modals/createAccountModal.tsx";
import { correctUserRoleName } from "./helpers/helpers.tsx";
import { useTranslation } from "react-i18next";

const AdminMenu = ({
  updateHeadquarters,
  updateGroups,
  updateUserList,
  selector,
}) => {
  const { userId, userRole } = useAuth();
  const [showCreateHQModal, setShowCreateHQModal] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

  const { t, i18n } = useTranslation();

  const handleCreateHQClick = async () => {
    setShowCreateHQModal(true);
  };

  const handleCreateGroupClick = async () => {
    setShowCreateGroupModal(true);
  };

  const handleCreateAccountClick = () => {
    setShowCreateAccountModal(true);
  };

  const handleCreateHQ = (newHeadquarter: any[]) => {
    updateHeadquarters(newHeadquarter);
    setShowCreateHQModal(false);
  };

  const handleCreateGroup = (newGroup: any[]) => {
    updateGroups(newGroup);
    setShowCreateGroupModal(false);
  };

  const handleCreateAccount = (newAccount: any[]) => {
    updateUserList(newAccount);
  };

  const renderCreateHQModal = () => {
    return (
      <div className={"adminContainer"}>
        <CreateHQModal
          setShowCreateHQModal={setShowCreateHQModal}
          handleCreateHQ={handleCreateHQ}
        />
      </div>
    );
  };

  const renderCreateGroupModal = () => {
    return (
      <div className={"adminContainer"}>
        <CreateGroupModal
          setShowCreateGroupModal={setShowCreateGroupModal}
          headquarterId={""}
          handleCreateGroup={handleCreateGroup}
          showSelectHQ={true}
        />
      </div>
    );
  };

  const renderCreateAccountModal = () => {
    return (
      <div className={"adminContainer"}>
        <CreateAccountModal
          setShowCreateAccountModal={setShowCreateAccountModal}
          groupId={""}
          headquarterId={""}
          userRole={"guest"}
          adminView={true}
          handleCreateAccount={handleCreateAccount}
        />
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#D4E5E2",
          borderRadius: "10px",
          marginTop: "86px",
          marginLeft: "50px",
          marginRight: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "70px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {selector === "Headquarters" && (
            <img
              src="/images/Icon_HQ.png"
              alt="Logo"
              style={{
                width: "6vh",
                minWidth: "20px",
                marginLeft: "2vh",
              }}
            />
          )}
          {selector === "Hospitals" && (
            <img
              src="/images/Icon_Hospital.png"
              alt="Logo"
              style={{
                width: "6vh",
                minWidth: "20px",
                marginLeft: "2vh",
              }}
            />
          )}
          {selector === "Users" && (
            <img
              src="/images/Icon_User_Simple.png"
              alt="Logo"
              style={{
                width: "4vh",
                minWidth: "20px",
                marginLeft: "2vh",
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "20px",
              color: "white",
              lineHeight: "1",
            }}
          >
            <div
              style={{
                textAlign: "left",
                paddingBottom: "8px",
                color: "#39a686",
                fontSize: "1.3em",
              }}
            >
              <strong>{selector === "Headquarters" ? t("Headquarters") : selector === "Hospitals" ? t("Hospitals") : selector === "Users" ? t("Users") : selector}</strong>
            </div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "VirtualLabQuickSandBold",
                color: "#212529",
                fontSize: "1.1em",
              }}
            >
              {t("Your_role")}:{" "}
              {
                <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
                  {correctUserRoleName(userRole, t)}
                </span>
              }
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "20px",
          }}
        >
          {selector === "Headquarters" && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleCreateHQClick}
              style={{
                backgroundColor: "#39A686",
                borderColor: "#39A686",
                borderRadius: "10px",
                marginRight: "10px",
                fontSize: "1em",
              }}
            >
              {t("create_new_headquarter")}
            </button>
          )}
          {selector === "Hospitals" && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleCreateGroupClick}
              style={{
                backgroundColor: "#39A686",
                borderColor: "#39A686",
                borderRadius: "10px",
                marginRight: "10px",
                fontSize: "1em",
              }}
            >
              {t("Create_new_hospital")}
            </button>
          )}
          {selector === "Users" && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleCreateAccountClick}
              style={{
                backgroundColor: "#39A686",
                borderColor: "#39A686",
                borderRadius: "10px",
                marginRight: "10px",
                fontSize: "1em",
              }}
            >
              {t("Create_new_User")}
            </button>
          )}
        </div>
      </div>
      {(showCreateHQModal && renderCreateHQModal()) ||
        (showCreateGroupModal && renderCreateGroupModal()) ||
        (showCreateAccountModal && renderCreateAccountModal())}
    </>
  );
};

export default AdminMenu;

import React, { useState } from "react";
import { API_BASE_URL } from "../../config.tsx";
import { useTranslation } from "react-i18next";

const ConfirmDeleteHQModal = ({
  setShowConfirmDeleteHQModal,
  headquarterId,
  headquarterName,
  handleDeleteHQ,
}) => {
  const { t, i18n } = useTranslation();

  const deleteHeadquarter = async (headquarterId: string | null) => {
    try {
      const response = await fetch(`${API_BASE_URL}/deleteHeadquarter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ headquarterId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete headquarter");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error delete headquarter:", error);
      throw new Error("An error occurred while delete headquarter");
    }
  };

  const handleDeleteHQClick = async (headquarterId: string | null) => {
    await deleteHeadquarter(headquarterId);
    handleDeleteHQ();
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div
          style={{ textAlign: "center", fontSize: "1.3em", marginTop: "2vh" }}
        >
          {t("Confirm_to_delete_headquarter")}
        </div>
        <label style={{ lineHeight: "1", marginTop: "2vh" }}>
          {t("This_is_irreversible_action.")}
          <br />
          {t("Every_user_in_the_headquarter_becomes_a_guest.")}
        </label>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5vh",
            maxHeight: "1%",
          }}
        >
          <img
            src="/images/Icon_HQ.png"
            style={{
              width: "5vh",
              minWidth: "25px",
              marginRight: "1vh",
            }}
          />
          <div className="userDataWrapper">{headquarterName}</div>
        </div>
        <div className="button-container">
          <button
            type="button"
            className="buttonCancel btn btn-primary"
            onClick={() => setShowConfirmDeleteHQModal(false)}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            className="buttonAccept btn btn-primary"
            onClick={() => handleDeleteHQClick(headquarterId)}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteHQModal;

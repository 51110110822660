import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../config.tsx";
import { getNameErrors } from "../helpers/helpers.tsx";
import { useTranslation } from "react-i18next";

const ChangeHQDataModal = ({
  setShowChangeDataModal,
  headquarterId,
  headquarterName,
  handleChangeData,
}) => {
  const [newHQName, setNewHQName] = useState("");
  const [saveDataSubmitted, setSaveDataSubmitted] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setNewHQName(headquarterName);
  }, []);

  const updateHQData = async (
    headquarterId: string | null,
    headquarterName: string
  ) => {
    try {
      const response = await fetch(`${API_BASE_URL}/updateHQData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ headquarterId, headquarterName }),
      });

      if (!response.ok) {
        throw new Error("Failed to update headquarter data");
      }
    } catch (error) {
      console.error("Error update headquarter data:", error);
      throw new Error("An error occurred while update headquarter data");
    }
  };

  const handleSaveDataClick = async () => {
    setSaveDataSubmitted(true);

    if (getNameErrors(newHQName, t)) return;

    await updateHQData(headquarterId, newHQName);
    handleChangeData(newHQName);
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div style={{ textAlign: "center", fontSize: "1.3em" }}>
          {t("Edit_Headquarter")}
        </div>
        <div className="mb-3" style={{ marginTop: "2vh" }}>
          <label
            htmlFor="nameInput"
            style={{
              marginLeft: "20px",
              color: "#404040",
              fontWeight: "bold",
            }}
          >
            {t("Name")}
          </label>
          <input
            type="text"
            id="nameInput"
            className="form-control"
            value={newHQName}
            onChange={(e) => {
              setNewHQName(e.target.value);
              setSaveDataSubmitted(false);
            }}
            style={{
              backgroundColor: "#CCCCCC",
              borderRadius: "20px",
              color: "#404040",
              height: "5vh",
              maxHeight: "40px",
              fontSize: "1em",
              paddingLeft: "20px",
            }}
          />
          <label
            htmlFor="nameInput"
            style={{
              marginLeft: "20px",
              color: "#A63939",
              fontSize: "0.8em",
              visibility:
                saveDataSubmitted && getNameErrors(newHQName, t)
                  ? "visible"
                  : "hidden",
            }}
          >
            {getNameErrors(newHQName, t)}
          </label>
        </div>

        <div className="button-container">
          <button
            type="button"
            className="buttonCancel btn btn-primary"
            onClick={() => {
              setShowChangeDataModal(false);
              setSaveDataSubmitted(false);
            }}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            className="btn btn-primary buttonAcceptGreen"
            onClick={() => handleSaveDataClick()}
          >
            {t("Save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeHQDataModal;

import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../firebase/auth.tsx";
import { checkForAdmin, getHeadquarterNameById } from "./helpers/requests.tsx";
import CreateGroupModal from "./modals/createGroupModal.tsx";
import ConfirmDeleteHQModal from "./modals/confirmDeleteHQModal.tsx";
import CreateAccountModal from "./modals/createAccountModal.tsx";
import ChangeHQDataModal from "./modals/changeHQDataModal.tsx";
import "../styles/admin.css";
import { correctUserRoleName } from "./helpers/helpers.tsx";
import { useTranslation } from "react-i18next";

const HeadquarterMenu = ({ updateGroups, updateUserList }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const params = useParams();
  const { headquarterId } = params;

  const { userId, userRole } = useAuth();

  const { t, i18n } = useTranslation();

  const [headquarterName, setHeadquarterName] = useState("");
  const [showGlobalAdminBlock, setShowGlobalAdminBlock] = useState(false);
  const [showChangeDataModal, setShowChangeDataModal] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showConfirmDeleteHQModal, setShowConfirmDeleteHQModal] =
    useState(false);
  const [showCreateHQAccountModal, setShowCreateHQAccountModal] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const isAdmin = await checkForAdmin(userId);
      setShowGlobalAdminBlock(isAdmin.admin);

      const data = await getHeadquarterNameById(headquarterId);
      setHeadquarterName(data);
    };

    fetchData();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleHQUsersClick = () => {
    navigate(`/userList/headquarter/${headquarterId}`);
  };

  const handleChangeDataClick = () => {
    setIsOptionsOpen(false);
    setShowChangeDataModal(true);
  };

  const handleCreateHQAccountClick = () => {
    setIsOptionsOpen(false);
    setShowCreateHQAccountModal(true);
  };

  const handleDeleteHQClick = () => {
    setIsOptionsOpen(false);
    setShowConfirmDeleteHQModal(true);
  };

  const handleCreateGroupClick = async () => {
    setIsOptionsOpen(false);
    setShowCreateGroupModal(true);
  };

  const handleCreateGroup = (newGroup: any[]) => {
    updateGroups(newGroup);
    setShowCreateGroupModal(false);
  };

  const handleDeleteHQSubmit = () => {
    navigate("/headquarterList");
  };

  const handleCreateHQAccount = (newAccount: any) => {
    updateUserList(newAccount);
  };

  const handleChangeData = async (newHQName: string) => {
    setHeadquarterName(newHQName);
    setShowChangeDataModal(false);
  };

  const renderChangeDataModal = () => {
    return (
      <div className={"adminContainer"}>
        <ChangeHQDataModal
          setShowChangeDataModal={setShowChangeDataModal}
          headquarterId={headquarterId}
          headquarterName={headquarterName}
          handleChangeData={handleChangeData}
        />
      </div>
    );
  };

  const renderCreateGroupModal = () => {
    return (
      <div className={"adminContainer"}>
        <CreateGroupModal
          setShowCreateGroupModal={setShowCreateGroupModal}
          headquarterId={headquarterId}
          handleCreateGroup={handleCreateGroup}
        />
      </div>
    );
  };

  const renderConfirmDeleteHQModal = () => {
    return (
      <div className={"adminContainer"}>
        <ConfirmDeleteHQModal
          setShowConfirmDeleteHQModal={setShowConfirmDeleteHQModal}
          headquarterId={headquarterId}
          headquarterName={headquarterName}
          handleDeleteHQ={handleDeleteHQSubmit}
        />
      </div>
    );
  };

  const renderCreateHQAccountModal = () => {
    return (
      <div className={"adminContainer"}>
        <CreateAccountModal
          setShowCreateAccountModal={setShowCreateHQAccountModal}
          groupId={""}
          headquarterId={headquarterId}
          userRole={"headquarterAdmin"}
          handleCreateAccount={handleCreateHQAccount}
        />
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#D4E5E2",
          borderRadius: "10px",
          marginTop: "86px",
          marginLeft: "50px",
          marginRight: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "70px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/images/Icon_HQ.png"
            alt="Logo"
            style={{
              width: "6vh",
              minWidth: "50px",
              marginLeft: "20px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "20px",
              color: "white",
              lineHeight: "1",
            }}
          >
            <div
              style={{
                textAlign: "left",
                paddingBottom: "8px",
                color: "#39a686",
                fontSize: "1.3em",
              }}
            >
              <strong>{headquarterName}</strong>
            </div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "VirtualLabQuickSandBold",
                color: "#212529",
                fontSize: "1.1em",
              }}
            >
              {t("Your_role")}:{" "}
              {
                <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
                  {correctUserRoleName(userRole, t)}
                </span>
              }
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "20px",
          }}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleHQUsersClick}
            style={{
              backgroundColor: "#39A686",
              borderColor: "#39A686",
              borderRadius: "10px",
              marginRight: "10px",
              fontSize: "1em",
            }}
          >
            {t("Headquarter_users")}
          </button>
          <div className="dropdown" ref={dropdownRef}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={toggleDropdown}
              style={{
                backgroundColor: "#39A686",
                borderColor: "#39A686",
                borderRadius: "10px",
                fontSize: "1em",
              }}
            >
              {t("Options")}
            </button>
            {isOptionsOpen && (
              <div className="dropdownMenu">
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    handleChangeDataClick();
                  }}
                >
                  <img src="/images/Icon_Edit.png" />
                  {t("Edit_Headquarter")}
                </button>
                <button
                  onClick={handleCreateGroupClick}
                  type="button"
                  className="btn"
                >
                  <img src="/images/Icon_Add.png" />
                  {t("Create_new_hospital")}
                </button>
                <button
                  onClick={handleCreateHQAccountClick}
                  type="button"
                  className="btn"
                >
                  <img src="/images/Icon_AddUser.png" />
                  {t("Create_new_headquarter_account")}
                </button>
                {showGlobalAdminBlock && (
                  <button
                    onClick={handleDeleteHQClick}
                    type="button"
                    className="btn"
                  >
                    <img src="/images/Icon_Delete.png" />
                    {t("Delete_Headquarter")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {(showChangeDataModal && renderChangeDataModal()) ||
        (showCreateGroupModal && renderCreateGroupModal()) ||
        (showConfirmDeleteHQModal && renderConfirmDeleteHQModal()) ||
        (showCreateHQAccountModal && renderCreateHQAccountModal())}
    </>
  );
};

export default HeadquarterMenu;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../config.tsx";
import "../App.css";
import styles from "../styles/allUsers.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ChangeUserDataModal from "./modals/changeUserDataModal.tsx";
import ConfirmDeleteUserModal from "./modals/confirmDeleteUserModal.tsx";
import ConfirmHideModal from "./modals/confirmHideModal.tsx";
import ConfirmPermissionModal from "./modals/confirmPermissionModal.tsx";
import { User } from "./helpers/interfaces.tsx";
import { useTranslation } from "react-i18next";
import { correctUserRoleName } from "./helpers/helpers.tsx";

interface SelectedTraining {
  id?: string;
  name?: string;
  entityId?: string;
  permission?: boolean;
  manufacturerId?: string;
  isHidden?: boolean;
}

const AllUsers = ({ userList, setUserList }) => {
  const { headquarterId, groupId } = useParams();

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const [trainingsList, setTrainingsList] = useState<any[]>([]);
  const [comingSoonManufacturers, setComingSoonManufacturers] = useState<any[]>(
    []
  );
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingTrainings, setIsLoadingTrainings] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);

  const [selectedUser, setSelectedUser] = useState<User>({});

  const [editingUsers, setEditingUsers] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [showConfirmDeleteUserModal, setShowConfirmDeleteUserModal] =
    useState(false);

  const [showChangeDataModal, setShowChangeDataModal] = useState(false);

  const [visibility, setVisibility] = useState({});
  const [showConfirmHideModal, setShowConfirmHideModal] = useState(false);
  const [showConfirmPermissionModal, setShowConfirmPermissionModal] =
    useState(false);
  const [selectedManufacturerId, setSelectedManufacturerId] = useState("");
  const [selectedManufacturerName, setSelectedManufacturerName] = useState("");
  const [selectedTraining, setSelectedTraining] = useState<SelectedTraining>(
    {}
  );

  const [streamingClientIds, setStreamingClientIds] = useState<string[]>([]);
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const ws = new WebSocket(`${API_BASE_URL}/ws/`);

    ws.onopen = () => {
      ws.send("getStreamingRooms");
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "clientList") {
        setStreamingClientIds(Object.keys(message.clients));
      }
    };

    const fetchData = async () => {
      setIsLoadingUsers(true);
      await getUsersList();
      setIsLoadingUsers(false);
    };

    fetchData();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);

      ws.close();

      setUserList([]);
      setSelectedUser({});
      setEditingUsers({});
      setTrainingsList([]);
    };
  }, [setUserList]);

  useEffect(() => {
    updateUserListWithStreamingStatus();
  }, [isLoadingUsers, streamingClientIds]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getUsersList = async () => {
    let requestTarget = "";
    let requestBody = null;

    if (headquarterId && !groupId) {
      requestTarget = "getUsersInHeadquarter";
      requestBody = JSON.stringify({ headquarterId });
    } else if (groupId) {
      requestTarget = "getUsersInGroup";
      requestBody = JSON.stringify({ groupId });
    } else {
      requestTarget = "getUsersList";
    }

    try {
      const response = await fetch(`${API_BASE_URL}/${requestTarget}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      });

      if (!response.ok) {
        throw new Error("Failed to get users list");
      }

      const users = await response.json();
      setUserList(users);
    } catch (error) {
      console.error("Error get users list:", error);
      throw new Error("An error occurred while get users list");
    }
  };

  const updateUserListWithStreamingStatus = () => {
    setUserList((prevUserList) =>
      prevUserList.map((user) => ({
        ...user,
        isStreaming: streamingClientIds.includes(user.id),
      }))
    );
  };

  const checkUserVerification = async (email: string | null) => {
    try {
      const response = await fetch(`${API_BASE_URL}/checkUserVerification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Failed to check verify user");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error verify user:", error);
      throw new Error("An error occurred while checking verify user");
    }
  };

  const verifyUser = async (userId: string | null) => {
    try {
      setIsUserVerified(true);

      const response = await fetch(`${API_BASE_URL}/verifyUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        throw new Error("Failed to verify user");
      }

      const userData = await response.json();
      return userData;
    } catch (error) {
      console.error("Error verify user:", error);
      throw new Error("An error occurred while verify user");
    }
  };

  const updatePermission = async (
    userId: string,
    trainingId: string,
    isChecked: boolean,
    isHidden: boolean = false
  ) => {
    try {
      const response = await fetch(`${API_BASE_URL}/updateTrainingPermission`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entityId: userId,
          entityType: "user",
          trainingId,
          isChecked,
          isHidden,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update training permission");
      }
    } catch (error) {
      console.error("Error update training permission:", error);
      throw new Error("An error occurred while update training permission");
    }
  };

  const fetchTrainingsList = async (userId: string) => {
    setIsLoadingTrainings(true);

    const showAll = !headquarterId && !groupId;

    try {
      const response = await fetch(
        `${API_BASE_URL}/getTrainingsListWithPermissions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            entityId: userId,
            entityType: "user",
            showAll: showAll,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get trainings list");
      }

      const trainings = await response.json();

      setTrainingsList(trainings);

      setVisibility((prevVisibility) => {
        const updatedVisibility = { ...prevVisibility };
        trainings.forEach((training) => {
          updatedVisibility[training.manufacturerId] =
            updatedVisibility[training.manufacturerId] || training.isHidden;
        });
        return updatedVisibility;
      });
    } catch (error) {
      console.error("Error get trainings list:", error);
      throw new Error("An error occurred while get trainings list");
    } finally {
      setIsLoadingTrainings(false);
    }
  };

  const fetchComingSoonManufacturers = async (userId: string) => {
    setIsLoadingTrainings(true);

    const showAll = !headquarterId && !groupId;

    try {
      const response = await fetch(
        `${API_BASE_URL}/getComingSoonManufacturers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            entityId: userId,
            entityType: "user",
            showAll,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get coming soon manufacturers");
      }

      const manufacturers = await response.json();

      setComingSoonManufacturers(manufacturers);

      setVisibility((prevVisibility) => {
        const updatedVisibility = { ...prevVisibility };
        manufacturers.forEach((manufacturer) => {
          updatedVisibility[manufacturer.id] = manufacturer.isHidden;
        });
        return updatedVisibility;
      });
    } catch (error) {
      console.error("Error get coming soon manufacturers:", error);
      throw new Error("An error occurred while get coming soon manufacturers");
    } finally {
      setIsLoadingTrainings(false);
    }
  };

  const toggleDropdown = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleDeleteUser = async () => {
    setUserList((prevUserList) =>
      prevUserList.filter((user) => user.id !== selectedUser.id)
    );
    setSelectedUser({});
    setEditingUsers({});
    setTrainingsList([]);

    setShowConfirmDeleteUserModal(false);
  };

  const handleChangeData = async (updatedUser: User) => {
    // Update the user list
    setUserList((prevUserList) =>
      prevUserList.map((user) =>
        user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      )
    );

    // Update the selected user state
    setSelectedUser((prevSelectedUser) =>
      prevSelectedUser.id === updatedUser.id
        ? { ...prevSelectedUser, ...updatedUser }
        : prevSelectedUser
    );

    // Update permissions rendering
    handleEditClick(updatedUser);

    setShowChangeDataModal(false);
  };

  const handleEditClick = async (user: User) => {
    setEditingUsers((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      newState[user.id] = true;
      return newState;
    });

    const verification = await checkUserVerification(user.email);
    setIsUserVerified(verification);

    setSelectedUser(user);

    await fetchTrainingsList(user.id);
    await fetchComingSoonManufacturers(user.id);
  };

  const handlePermissionChange = async (
    trainingId: string,
    targetId: string,
    permission: boolean
  ) => {
    await updatePermission(targetId, trainingId, permission);
    setTrainingsList(
      trainingsList.map((training) => {
        if (training.id === trainingId) {
          return { ...training, permission: permission };
        }

        return training;
      })
    );
    setSelectedTraining({});
  };

  const handleHideClick = (manufacturerId) => {
    setShowConfirmHideModal(false);
    const isHidden = visibility[manufacturerId];
    if (
      comingSoonManufacturers.some(
        (comingSoonManufacturer) => comingSoonManufacturer.id === manufacturerId
      )
    ) {
      // Update permissions to false for manufacturer
      const updatedComingSoonManufacturers = comingSoonManufacturers.map(
        (comingSoonManufacturer) => {
          if (comingSoonManufacturer.id === manufacturerId) {
            updatePermission(
              selectedUser.id,
              comingSoonManufacturer.id,
              true,
              !isHidden
            );
            return { ...comingSoonManufacturer, isHidden: !isHidden };
          }
          return comingSoonManufacturer;
        }
      );

      setComingSoonManufacturers(updatedComingSoonManufacturers);
    } else {
      // Update permissions to false for all trainings under this manufacturer
      const updatedTrainings = trainingsList.map((training) => {
        if (training.manufacturerId === manufacturerId) {
          updatePermission(selectedUser.id, training.id, false, !isHidden);
          return { ...training, permission: false, isHidden: !isHidden };
        }
        return training;
      });

      setTrainingsList(updatedTrainings);
    }

    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [manufacturerId]: !prevVisibility[manufacturerId],
    }));
  };

  const handleStreamClick = (userId) => {
    navigate(`/stream/${userId}`);
  };

  const renderConfirmDeleteUserModal = () => {
    return (
      <div className={"adminContainer"}>
        <ConfirmDeleteUserModal
          setShowConfirmDeleteUserModal={setShowConfirmDeleteUserModal}
          user={selectedUser}
          handleDeleteUser={handleDeleteUser}
        />
      </div>
    );
  };

  const renderChangeDataModal = () => {
    return (
      <div className={"adminContainer"}>
        <ChangeUserDataModal
          setShowChangeDataModal={setShowChangeDataModal}
          user={selectedUser}
          handleChangeData={handleChangeData}
          adminView={!headquarterId && !groupId}
          headquarterView={!!headquarterId && !groupId}
          groupView={!!groupId}
        />
      </div>
    );
  };

  const renderConfirmHideModal = () => {
    const isHidden = visibility[selectedManufacturerId];
    if (isHidden) handleHideClick(selectedManufacturerId);
    else {
      return (
        <ConfirmHideModal
          setShowConfirmHideModal={setShowConfirmHideModal}
          manufacturerId={selectedManufacturerId}
          manufacturerName={selectedManufacturerName}
          usersView={true}
          handleHideClick={handleHideClick}
        />
      );
    }
  };

  const renderConfirmPermissionModal = () => {
    return (
      <ConfirmPermissionModal
        setShowConfirmPermissionModal={setShowConfirmPermissionModal}
        trainingId={selectedTraining.id}
        trainingName={selectedTraining.name}
        targetId={selectedTraining.entityId}
        targetName={selectedUser.firstName + " " + selectedUser.lastName}
        permission={selectedTraining.permission}
        handlePermissionChange={handlePermissionChange}
      />
    );
  };

  const groupTrainingsByManufacturer = (trainings) => {
    const groupedTrainings = {};
    trainings.forEach((training) => {
      const {
        id,
        name,
        manufacturerId,
        manufacturerName,
        permission,
        entityId,
        isHidden,
      } = training;
      if (!groupedTrainings[manufacturerId]) {
        groupedTrainings[manufacturerId] = {
          manufacturerName: manufacturerName,
          trainings: [],
        };
      }
      groupedTrainings[manufacturerId].trainings.push({
        id: id,
        name: name,
        permission: permission,
        entityId: entityId,
        isHidden: isHidden,
        manufacturerName: manufacturerName,
      });
    });

    return groupedTrainings;
  };

  const renderGroupedTrainings = (groupedTrainings: {
    [manufacturerId: string]: {
      manufacturerName: string;
      trainings: {
        id: string;
        entityId: string;
        name: string;
        permission: boolean;
        isHidden: boolean;
      }[];
    };
  }) => {
    return (
      <>
        {Object.entries(groupedTrainings).map(
          ([manufacturerId, { manufacturerName, trainings }]) => {
            const isAnyTrainingHidden = trainings.some(
              (training) => training.isHidden
            );
            return (
              <div key={manufacturerId} className={styles.trainingsGroup}>
                <label
                  className={styles.trainingsGroupTitle}
                  style={{ color: isAnyTrainingHidden ? "gray" : "#39a686" }}
                >
                  {manufacturerName}
                </label>
                {!headquarterId && !groupId && (
                  <span
                    onClick={() => {
                      setSelectedManufacturerId(manufacturerId);
                      setSelectedManufacturerName(manufacturerName);
                      setShowConfirmHideModal(true);
                    }}
                    style={{
                      marginLeft: "1vh",
                      fontSize: "0.7em",
                      cursor: "pointer",
                    }}
                  >
                    {isAnyTrainingHidden ? t("show") : t("hide")}
                  </span>
                )}
                <ul>
                  {trainings.map((training) => (
                    <li
                      key={training.id}
                      className="form-check custom-checkbox"
                    >
                      <label className="custom-checkbox-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="agreeCheckbox"
                          checked={training.permission}
                          disabled={training.isHidden}
                          onChange={(e) => {
                            setSelectedTraining((prevInfo) => ({
                              ...prevInfo,
                              id: training.id,
                              name: training.name,
                              entityId: training.entityId,
                              permission: training.permission,
                            }));
                            setShowConfirmPermissionModal(true);
                          }}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">
                          {training.name}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            );
          }
        )}
        {comingSoonManufacturers.length > 0 && (
          <label
            className={styles.trainingsGroupTitle}
            style={{ color: "#39a686" }}
          >
            {t("Coming_soon")}:
          </label>
        )}
        {comingSoonManufacturers.map(({ id, name, isHidden }) => {
          return (
            <div key={id} className={styles.trainingsGroup}>
              <label
                className={styles.trainingsGroupTitle}
                style={{ color: isHidden ? "gray" : "#212529" }}
              >
                {name}
              </label>
              {!headquarterId && !groupId && (
                <span
                  onClick={() => {
                    setSelectedManufacturerId(id);
                    setSelectedManufacturerName(name);
                    setShowConfirmHideModal(true);
                  }}
                  style={{
                    marginLeft: "1vh",
                    fontSize: "0.7em",
                    cursor: "pointer",
                  }}
                >
                  {isHidden ? t("show") : t("hide")}
                </span>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const renderUserRole = (role) => {
    if (role === "user" || role === "guest") return "";

    return (
      <span
        style={{
          color: "#39a686",
          marginLeft: "1vh",
        }}
      >
        | {correctUserRoleName(role, t)}
      </span>
    );
  };

  const renderUserGroupCountry = (countryCode) => {
    return (
      <span
        style={{
          color: "#39a686",
          marginLeft: "1vh",
        }}
      >
        | {t("country_" + countryCode)}
      </span>
    );
  };

  return (
    <div className={styles.adminContainer}>
      {isLoadingUsers ? (
        <p className={styles.loading}>{t("Loading...")}</p>
      ) : (
        <>
          {/* Left side */}
          <div className={styles.leftContainer}>
            {/* Separate table header - don't find other way to avoid overlaping header on scroll */}
            <div className={styles.tableHeader}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: `calc(100%)` }}>
                      {t("User")}
                    </th>
                    {!headquarterId && !groupId && (
                      <th scope="col" style={{ width: `calc(100% / 3)` }}>
                        {t("Headquarter")}
                      </th>
                    )}
                    {!groupId && (
                      <th scope="col" style={{ width: `calc(100% / 3)` }}>
                        {t("Hospital")}
                      </th>
                    )}
                    <th scope="col" style={{ width: `calc(100% / 3)` }}></th>
                    <th scope="col" className={styles.editButtonWrapper}></th>
                  </tr>
                </thead>
              </table>
            </div>

            {/* Table with restricted height and scrollbar */}
            <div className={styles.tableContent}>
              <div className={`container-fluid ${styles.containerFluid}`}>
                <table className="table">
                  <tbody>
                    {userList.map((user: User, index: number) => (
                      <tr
                        key={user.id}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#D4E5E2" : "transparent",
                        }}
                      >
                        <td style={{ width: `calc(100%)` }}>
                          <div className={styles.containerHeader}>
                            <img
                              src="/images/Icon_User_Simple.png"
                              style={{
                                width: "2.2vh",
                                marginLeft: "0.3vh",
                                marginRight: "1.5vh",
                              }}
                            />
                            <div className={styles.userDataWrapper}>
                              <div className={styles.userDataName}>
                                {user.firstName} {user.lastName}
                                {renderUserRole(user.role)}
                              </div>
                              <div className={styles.userDataEmail}>
                                {user.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        {!headquarterId && !groupId && (
                          <td style={{ width: `calc(100% / 3)` }}>
                            {user.headquarterName}
                          </td>
                        )}
                        {!groupId && (
                          <td style={{ width: `calc(100% / 3)` }}>
                            {user.groupName}
                            {user.groupName && (
                              <span
                                style={{
                                  color: "#39a686",
                                }}
                              >
                                {renderUserGroupCountry(user.groupCountryCode)}
                              </span>
                            )}
                          </td>
                        )}
                        <td
                          style={{
                            color: "#39a686",
                            textAlign: "center",
                            width: `calc(100% / 3)`,
                          }}
                        >
                          {user.isStreaming && (
                            <>
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "20px",
                                  textAlign: "center",
                                }}
                              >
                                {isVisible
                                  ? String.fromCharCode(0x2b24)
                                  : "\u2003"}
                              </span>
                              <span>{t("Streaming")}</span>
                            </>
                          )}
                        </td>
                        <td className={styles.editButtonWrapper}>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleEditClick(user)}
                            style={{
                              backgroundColor: editingUsers[user.id]
                                ? "#404040"
                                : "#39A686",
                              borderColor: editingUsers[user.id]
                                ? "#404040"
                                : "#39A686",
                            }}
                          >
                            {t("Details")}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Right side */}
          <div className={styles.rightContainer}>
            {selectedUser.id ? (
              <>
                <div className={styles.containerHeaderWrapper}>
                  <div className={styles.containerHeader}>
                    <img
                      className={styles.userDataImage}
                      src="/images/Icon_User_Simple.png"
                    />
                    <div className={styles.userDataWrapper}>
                      <div className={styles.userDataName}>
                        {selectedUser.firstName + " " + selectedUser.lastName}
                      </div>
                      <div className={styles.userDataEmail}>
                        {selectedUser.email}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!isUserVerified && (
                      <>
                        <button
                          type="button"
                          className={`${styles.buttonVerify} btn btn-primary`}
                          onClick={() => verifyUser(selectedUser.id)}
                        >
                          {t("Verify")}
                        </button>
                      </>
                    )}
                    {selectedUser.isStreaming && (
                      <>
                        <button
                          type="button"
                          className={`${styles.buttonStream} btn btn-primary`}
                          onClick={() => handleStreamClick(selectedUser.id)}
                        >
                          {t("Stream")}
                        </button>
                      </>
                    )}
                    <div className="dropdown" ref={dropdownRef}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={toggleDropdown}
                        style={{
                          width: "13vh",
                          minWidth: "60px",
                          backgroundColor: "#39A686",
                          borderColor: "#39A686",
                          borderRadius: "10px",
                          fontSize: "1em",
                          fontFamily: "VirtualLabQuickSandLight",
                          fontWeight: "bold",
                          paddingRight: "30px",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "1.8vh",
                          }}
                        >
                          &#9013;
                        </span>
                        {t("Options")}
                      </button>
                      {isOptionsOpen && (
                        <div className={styles.dropdownMenu}>
                          <button
                            type="button"
                            onClick={() => {
                              setIsOptionsOpen(false);
                              setShowChangeDataModal(true);
                            }}
                            className="btn"
                          >
                            <img src="/images/Icon_Edit.png" />
                            {t("Edit_User")}
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              setIsOptionsOpen(false);
                              setShowConfirmDeleteUserModal(true);
                            }}
                            className="btn"
                          >
                            <img src="/images/Icon_Delete.png" />
                            {t("Delete_User")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <hr />

                  <p className={styles.containerHeaderTitle}>
                    {t("Training_permission")}
                  </p>
                  <p className={styles.containerHeaderDescription}>
                    {t("Set_user_access_to_these_trainings_in_the_VR_application")}
                  </p>
                </div>

                <div className={`${styles.trainingsContainer} thin-scrollbar`}>
                  {isLoadingTrainings ? (
                    <p>{t("Loading...")}</p>
                  ) : (
                    renderGroupedTrainings(
                      groupTrainingsByManufacturer(trainingsList)
                    )
                  )}
                </div>
              </>
            ) : (
              <p className={styles.selectUser}>{t("Select_user_to_edit")}</p>
            )}
          </div>
        </>
      )}
      {(showConfirmDeleteUserModal && renderConfirmDeleteUserModal()) ||
        (showChangeDataModal && renderChangeDataModal()) ||
        (showConfirmHideModal && renderConfirmHideModal()) ||
        (showConfirmPermissionModal && renderConfirmPermissionModal())}
    </div>
  );
};
export default AllUsers;

import "../../styles/admin.css";
import { useTranslation } from "react-i18next";

const ConfirmHideModal = ({
  setShowConfirmHideModal,
  manufacturerId,
  manufacturerName,
  headquarterView = false,
  groupsView = false,
  usersView = false,
  handleHideClick,
}) => {
  const { t, i18n } = useTranslation();

  let text = "";
  if (headquarterView) text = t("for_all_users_in_the_headquarter");
  else if (groupsView) text = t("for_all_users_in_the_hospital");
  else if (usersView) text = t("for_selected_user");

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div
          style={{ textAlign: "center", fontSize: "1.3em", marginTop: "2vh" }}
        >
          {t("Confirm_to_hide_manufacturer")}
        </div>
        <label style={{ lineHeight: "1", marginTop: "2vh" }}>
          {t("This_is_irreversible_action.")}
          <br />
          {t("All_manufacturer_trainings_will_be_deactivated_and_hided")} {text}
        </label>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5vh",
            maxHeight: "1%",
          }}
        >
          <img
            src="/images/Icon_HQ.png"
            style={{
              width: "5vh",
              minWidth: "25px",
              marginRight: "1vh",
            }}
          />
          <div className="userDataWrapper">{manufacturerName}</div>
        </div>

        <div className="button-container">
          <button
            type="button"
            className="buttonCancel btn btn-primary"
            onClick={() => setShowConfirmHideModal(false)}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            className="buttonAccept btn btn-primary"
            onClick={() => handleHideClick(manufacturerId)}
          >
            {t("Hide")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmHideModal;

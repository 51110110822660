import { API_BASE_URL } from "../../config.tsx";
import "../../styles/admin.css";
import { useTranslation } from "react-i18next";

const ConfirmDeleteGroupModal = ({
  setShowConfirmDeleteGroupModal,
  groupId,
  groupName,
  groupCountryCode,
  handleDeleteGroupSubmit,
}) => {
  const { t, i18n } = useTranslation();

  const deleteGroup = async (groupId: string | null) => {
    try {
      const response = await fetch(`${API_BASE_URL}/deleteGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ groupId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete group");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error delete group:", error);
      throw new Error("An error occurred while delete group");
    }
  };

  const handleDeleteGroupClick = async (groupId: string | null) => {
    setShowConfirmDeleteGroupModal(false);
    await deleteGroup(groupId);
    handleDeleteGroupSubmit();
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div
          style={{ textAlign: "center", fontSize: "1.3em", marginTop: "2vh" }}
        >
          {t("Confirm_to_delete_hospital")}
        </div>
        <label style={{ lineHeight: "1", marginTop: "2vh" }}>
          {t("This_is_irreversible_action.")}
          <br />
          {t("Every_user_in_the_hospital_becomes_a_guest.")}
        </label>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5vh",
            maxHeight: "1%",
          }}
        >
          <img
            src="/images/Icon_Hospital.png"
            style={{
              width: "5vh",
              minWidth: "25px",
              marginRight: "1vh",
            }}
          />
          <div className="userDataWrapper">
            {groupName} | {t("country_" + groupCountryCode)}
          </div>
        </div>

        <div className="button-container">
          <button
            type="button"
            className="buttonCancel btn btn-primary"
            onClick={() => setShowConfirmDeleteGroupModal(false)}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            className="buttonAccept btn btn-primary"
            onClick={() => handleDeleteGroupClick(groupId)}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteGroupModal;

import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../firebase/auth.tsx";
import { useTranslation } from "react-i18next";

const DefaultPageView: React.FC = () => {
  const { userRole } = useAuth();
  const { t, i18n } = useTranslation();
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const { changeLanguage } = useAuth();
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const languageImages = {
    en: "/images/flags/English.png",
    de: "/images/flags/German.png",
    cz: "/images/flags/Czech.png",
  };

  // Preload language images
  useEffect(() => {
    Object.values(languageImages).forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsLanguageMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLanguageClick = (newLanguage) => {
    toggleLanguageMenu();
    changeLanguage(newLanguage);
  };

  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen((prev) => !prev);
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <img
        src="/images/Background_Element_TopLeft.png"
        alt="Top Left Image"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          maxWidth: "20%",
          width: "auto",
          maxHeight: "20vh",
          height: "auto",
          zIndex: -1,
        }}
      />
      {!userRole && (
        <div
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            zIndex: 10,
          }}
          ref={dropdownRef}
        >
          <button
            onClick={toggleLanguageMenu}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <img
              src={languageImages[i18n.language as keyof typeof languageImages]}
              alt="Language"
              style={{ width: "30px", height: "20px" }}
            />
          </button>
          {isLanguageMenuOpen && (
            <div
              style={{
                position: "absolute",
                top: "30px",
                marginLeft: "-4px",
                background: "#cccccc",
                boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                borderRadius: "5px",
                zIndex: "20",
              }}
            >
              {Object.entries(languageImages).map(([lang, src]) => (
                <div
                  key={lang}
                  onClick={() => handleLanguageClick(lang)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={src}
                    alt={lang}
                    style={{ width: "30px", height: "20px" }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <img
        src="/images/Background_Element_BottomRight.png"
        alt="Bottom Right Image"
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          maxWidth: "20%",
          width: "auto",
          maxHeight: "20vh",
          height: "auto",
          zIndex: -1,
        }}
      />
    </div>
  );
};

export default DefaultPageView;

import "../../styles/admin.css";
import { useTranslation } from "react-i18next";

const ConfirmPermissionModal = ({
  setShowConfirmPermissionModal,
  trainingId,
  trainingName,
  targetId,
  targetName,
  permission,
  handlePermissionChange,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div
          style={{ textAlign: "center", fontSize: "1.3em", marginTop: "2vh" }}
        >
          {t("Confirm_to_" +(permission ? "remove" : "add") + "_the_permission")}
        </div>
        <label style={{ lineHeight: "1", marginTop: "2vh" }}>
          {t("Training")}: {trainingName}
          <br />
          <br />
          {t("Object")}: {targetName}
        </label>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5vh",
            maxHeight: "1%",
          }}
        >
          <div className="userDataWrapper">{}</div>
        </div>

        <div className="button-container">
          <button
            type="button"
            className="buttonCancel btn btn-primary"
            onClick={() => setShowConfirmPermissionModal(false)}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            className="buttonAccept btn btn-primary"
            onClick={() => {
              setShowConfirmPermissionModal(false);
              handlePermissionChange(trainingId, targetId, !permission);
            }}
          >
            {t("Confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPermissionModal;

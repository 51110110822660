import React, { useState } from "react";
import { useAuth } from "../firebase/auth.tsx";
import { useNavigate } from "react-router-dom";
import {
  getPasswordErrors,
  isEmailValid,
  getFirstNameErrors,
  getLastNameErrors,
} from "./helpers/helpers.tsx";
import { useTranslation } from "react-i18next";

import "../App.css";

const Register: React.FC = () => {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFormSubmitted(true);

    if (
      !isEmailValid(email) ||
      getPasswordErrors(password, confirmPassword, t) ||
      getFirstNameErrors(firstName, t) ||
      getLastNameErrors(lastName, t) ||
      !agreed
    ) {
      return;
    }

    await register(email, password, firstName, lastName, "", "", "guest");
  };

  const handleSignInClick = () => {
    navigate("/login");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 40px)",
        paddingTop: "40px",
        maxWidth: "450px",
        margin: "0 auto",
      }}
    >
      <div className="w-100 m-4">
        <img
          src="/images/VR_ICU_Logo_Main.png"
          alt="Logo"
          className="mb-3"
          style={{
            width: "calc(20vh - 20px)",
            position: "absolute",
            top: "3vh",
            left: "7vh",
          }}
        />
        <h4 style={{ textAlign: "center" }}>{t("Register_user")}</h4>
        <form onSubmit={handleSubmit} className="m-4">
          <div className="mb-3" style={{ marginTop: "3vh" }}>
            <label
              htmlFor="emailInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
              }}
            >
              {t("Email")}
            </label>
            <input
              className="form-control"
              id="emailInput"
              placeholder="example@site.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
            <label
              htmlFor="emailInput"
              style={{
                marginLeft: "20px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && !isEmailValid(email) ? "visible" : "hidden",
              }}
            >
              {t("This_email_is_invalid")}
            </label>
          </div>
          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="passwordInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
              }}
            >
              {t("Password")}
            </label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="passwordInput"
              placeholder={t("Minimum_6_characters")}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
          </div>
          <div>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="passwordConfirmInput"
              placeholder={t("Confirm_password")}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
          </div>
          <label
            htmlFor="passwordInput"
            style={{
              marginLeft: "20px",
              color: "#A63939",
              fontSize: "0.8em",
              visibility:
                formSubmitted && getPasswordErrors(password, confirmPassword, t)
                  ? "visible"
                  : "hidden",
            }}
          >
            {getPasswordErrors(password, confirmPassword, t)}
          </label>
          <div className="mb-3 form-check custom-checkbox">
            <label
              htmlFor="showPasswordCheckbox"
              className="custom-checkbox-label"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="showPasswordCheckbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
              <span className="checkmark"></span>
              <span
                className="form-check-label"
                style={{
                  fontFamily: "VirtualLabQuickSandLight",
                  fontSize: "0.8em",
                  fontWeight: "bold",
                  marginTop: "7px",
                }}
              >
                {t("Show_Password")}
              </span>
            </label>
          </div>
          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="firstNameInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
              }}
            >
              {t("First_Name")}
            </label>
            <input
              type="text"
              className="form-control"
              id="firstNameInput"
              placeholder="John"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
            <label
              htmlFor="firstNameInput"
              style={{
                marginLeft: "20px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && getFirstNameErrors(firstName, t)
                    ? "visible"
                    : "hidden",
              }}
            >
              {getFirstNameErrors(firstName, t)}
            </label>
          </div>

          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="lastNameInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
              }}
            >
              {t("Last_Name")}
            </label>
            <input
              type="text"
              className="form-control"
              id="lastNameInput"
              placeholder="Smith"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
            <label
              htmlFor="lastNameInput"
              style={{
                marginLeft: "20px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && getLastNameErrors(lastName, t)
                    ? "visible"
                    : "hidden",
              }}
            >
              {getLastNameErrors(lastName, t)}
            </label>
          </div>
          <div
            className="mb-3 form-check custom-checkbox"
            style={{ marginTop: "2vh" }}
          >
            <label htmlFor="agreeCheckbox" className="custom-checkbox-label">
              <input
                type="checkbox"
                className="form-check-input"
                id="agreeCheckbox"
                checked={agreed}
                onChange={(e) => {
                  setAgreed(!agreed);
                  setFormSubmitted(false);
                }}
              />
              <span className="checkmark"></span>
              <span
                className="form-check-label"
                style={{
                  fontFamily: "VirtualLabQuickSandLight",
                  fontSize: "0.8em",
                  fontWeight: "bold",
                  marginTop: "7px",
                }}
              >
                {t("By_checking_this_box_you_agree_our")}{" "}
                <a href="#" style={{ color: "#404040" }}>
                  {t("Terms_of_Service")}
                </a>{" "}
                {t("and")}{" "}
                <a href="#" style={{ color: "#404040" }}>
                  {t("Privacy_Policy")}
                </a>
              </span>
            </label>
            <label
              htmlFor="agreeCheckbox"
              style={{
                color: "#A63939",
                fontSize: "0.8em",
                visibility: formSubmitted && !agreed ? "visible" : "hidden",
              }}
            >
              {t("You_must_accept_the_policies_in_order_to_use_VR_ICU")}
            </label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "3vh",
            }}
          >
            <button
              type="submit"
              className="btn btn-primary mb-2"
              style={{
                width: "45%",
                height: "5vh",
                maxHeight: "40px",
                maxWidth: "100%",
                borderRadius: "20px",
                marginTop: "10px",
                backgroundColor: "#39A686",
                borderColor: "#39A686",
              }}
              disabled={formSubmitted}
            >
              {t("Create_new_user")}
            </button>
            <button
              type="button"
              className="btn btn-primary mb-2"
              onClick={handleSignInClick}
              style={{
                width: "60%",
                maxWidth: "100%",
                borderRadius: "20px",
                marginTop: "10px",
                background: "none",
                borderColor: "transparent",
                color: "#404040",
              }}
            >
              {t("Already_have_account_Log_In")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;

import React, { useEffect, useState } from "react";
import { useAuth } from "../firebase/auth.tsx";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config.tsx";
import { useTranslation } from "react-i18next";

import "../App.css";

const Verification: React.FC = () => {
  const navigate = useNavigate();
  const { userRole, user, repeatVerification } = useAuth();
  const [resendText, setResendText] = useState(true);
  const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(
    parseInt(localStorage.getItem("timer") || "60")
  );
  const [isVerified, setIsVerified] = useState(false);
  const { t, i18n } = useTranslation();

  const resendEmail = () => {
    repeatVerification();
    setResendText(false);
    setShowTimer(true);

    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          setShowTimer(false);
          setResendText(true);
          localStorage.removeItem("timer");
          return 60;
        } else {
          localStorage.setItem("timer", String(prevTimer - 1));
          return prevTimer - 1;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    // User is already verified
    if (userRole || !user) {
      navigate("/main");
      return;
    }

    const getQueryParamsFromUrl = (url) => {
      const queryStart = url.indexOf("?");
      const queryString = url.substring(queryStart + 1);
      return new URLSearchParams(queryString);
    };

    const handleEmailVerification = async () => {
      const params = getQueryParamsFromUrl(window.location.href);
      const oobCode = params.get("oobCode");
      if (oobCode) {
        try {
          await verifyUserByCode(oobCode);
          setIsVerified(true);
        } catch (error) {
          console.error("Email verification error:", error);
        }
      }
    };

    handleEmailVerification();

    const storedTimer = parseInt(localStorage.getItem("timer") || "60");
    if (storedTimer < 60) {
      setTimer(storedTimer);
      setResendText(false);
      setShowTimer(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setShowTimer(false);
            setResendText(true);
            localStorage.removeItem("timer");
            return 60;
          } else {
            localStorage.setItem("timer", String(prevTimer - 1));
            return prevTimer - 1;
          }
        });
      }, 1000);
    }
  }, []);

  const verifyUserByCode = async (oobCode) => {
    try {
      const response = await fetch(`${API_BASE_URL}/verifyUserByCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ oobCode }),
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error resending email verification:", error);
      throw new Error("An error occurred while resending email verification");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 40px)",
        paddingTop: "40px",
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      <div className="w-100 m-4">
        <img
          src="/images/VR_ICU_Logo_Main.png"
          alt="Logo"
          className="mb-3"
          style={{
            width: "calc(20vh - 20px)",
            position: "absolute",
            top: "3vh",
            left: "7vh",
          }}
        />
        {!isVerified && (
          <>
            <h4 style={{ textAlign: "center" }}>
              {t("Please_verify_your_email")}
            </h4>
            <div style={{ textAlign: "center" }}>
              <label
                style={{
                  marginLeft: "20px",
                  marginTop: "20px",
                  color: "#404040",
                  fontFamily: "VirtualLabQuickSandMedium",
                  fontSize: "1.4em",
                }}
              >
                {t(
                  "Once_you_verify_your_email_address_you_can_get_started_in_VR_ICU"
                )}
              </label>
            </div>
            <div style={{ textAlign: "center" }}>
              <label
                style={{
                  marginLeft: "20px",
                  marginTop: "12vh",
                  color: "#404040",
                  fontFamily: "VirtualLabQuickSandMedium",
                  fontSize: "1.4em",
                }}
              >
                {t("Didnt_receive_an_email")}?{" "}
                {resendText ? (
                  <span
                    style={{
                      color: "#404040",
                      fontWeight: "bold",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={resendEmail}
                  >
                    {t("Resend_email")}
                  </span>
                ) : (
                  <span style={{ color: "#44963C", fontWeight: "bold" }}>
                    {t("Email_sent")}
                  </span>
                )}
              </label>
            </div>
            <div style={{ textAlign: "center" }}>
              <label
                htmlFor="emailInput"
                style={{
                  marginLeft: "20px",
                  color: "#404040",
                  fontFamily: "VirtualLabQuickSandMedium",
                  fontSize: "1.4em",
                  visibility: showTimer ? "visible" : "hidden",
                }}
              >
                {t("Wait")} <span style={{ fontWeight: "bold" }}>{timer}</span>{" "}
                {timer !== 1 ? t("seconds") : t("second")}{" "}
                {t("to_resend_another_email")}
              </label>
            </div>
          </>
        )}
        {isVerified && (
          <>
            <h5 style={{ textAlign: "center" }}>
              {t("Your_email_has_been_verified")}
            </h5>
            <div style={{ textAlign: "center" }}>
              <span
                style={{
                  color: "#404040",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => navigate("/login")}
              >
                {t("Return_to_login_page")}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Verification;

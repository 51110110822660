import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage.jsx";
import { API_BASE_URL } from "../config.tsx";
import { useTranslation } from "react-i18next";

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextType {
  user: string | null;
  userId: string;
  userRole: string | null;
  headquarterId: string | null;
  firstName: string | null;
  lastName: string | null;
  language: string | null;
  login: (email: string, password: string) => void;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    groupId: string,
    headquarterId: string,
    role: string
  ) => any;
  repeatVerification: () => void;
  logout: () => void;
  changeLanguage: (newLanguage: string) => Promise<void>;
  isLanguageReady: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const signIn = async (email, password) => {
  try {
    const response = await fetch(`${API_BASE_URL}/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const userData = await response.json();

    if (response.ok) {
      return userData;
    }
  } catch (error) {
    console.error("Error signing in:", error);
    throw new Error("An error occurred while signing in");
  }
};

const signUp = async (
  email,
  password,
  firstName,
  lastName,
  groupId,
  headquarterId,
  role
) => {
  const response = await fetch(`${API_BASE_URL}/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
      firstName,
      lastName,
      groupId,
      headquarterId,
      role,
    }),
  });

  const userData = await response.json();

  if (!response.ok) {
    alert(userData.error);
    return null;
  } else {
    return userData;
  }
};

const resendEmailVerification = async (email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/resendEmailVerification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error resending email verification:", error);
    throw new Error("An error occurred while resending email verification");
  }
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [userId, setUserId] = useLocalStorage("userId", null);
  const [userRole, setRole] = useLocalStorage("userRole", null);
  const [headquarterId, setHeadquarterId] = useLocalStorage(
    "headquarterId",
    null
  );
  const [groupId, setGroupId] = useLocalStorage("groupId", null);
  const [firstName, setFirstName] = useLocalStorage("firstName", null);
  const [lastName, setLastName] = useLocalStorage("lastName", null);
  const [language, setLanguage] = useLocalStorage("language", "en");
  const [isLanguageReady, setIsLanguageReady] = useState(false);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeLanguage = async () => {
      if (i18n.language !== language) {
        await i18n.changeLanguage(language);
      }
      setIsLanguageReady(true);
    };
    initializeLanguage();
  }, [i18n, language]);

  const login = async (email: string, password: string) => {
    const userData = await signIn(email, password);

    setUser(email);
    setUserId(userData.userId);

    if (!userData.verification) {
      navigate("/verification");
      return;
    }

    setRole(userData.role);
    setHeadquarterId(userData.headquarterId);
    setGroupId(userData.groupId);
    setFirstName(userData.firstName);
    setLastName(userData.lastName);

    userRole === "admin" ? navigate("/headquarterList") : navigate("/main");
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    groupId: string,
    headquarterId: string,
    role: string
  ) => {
    const userData = await signUp(
      email,
      password,
      firstName,
      lastName,
      groupId,
      headquarterId,
      role
    );

    if (!user && userData) {
      login(email, password);
    }

    return userData;
  };

  const repeatVerification = async () => {
    const result = await resendEmailVerification(user);
    if (!result) {
      navigate("/main");
      return;
    }
  };

  const logout = () => {
    setUser(null);
    setUserId(null);
    setRole(null);
    setHeadquarterId(null);
    setGroupId(null);
    setFirstName(null);
    setLastName(null);
    navigate("/login");
  };

  const changeLanguage = async (newLanguage: string) => {
    await i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  const value = useMemo(
    () => ({
      user,
      userId,
      userRole,
      headquarterId,
      groupId,
      firstName,
      lastName,
      language,
      login,
      register,
      repeatVerification,
      logout,
      changeLanguage,
      isLanguageReady,
    }),
    [
      user,
      userId,
      userRole,
      headquarterId,
      groupId,
      firstName,
      lastName,
      language,
      isLanguageReady,
    ]
  );

  if (!isLanguageReady) {
    return <></>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

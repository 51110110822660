import { API_BASE_URL } from "../../config.tsx";

export const getNameErrors = (name: string, t: (key: string) => string) => {
  const pattern = /^[a-zA-Z0-9\-\'\s]+$/;

  if (name.trim() === "") {
    return t("Name_is_required");
  }

  if (!pattern.test(name)) {
    return t("Invalid_characters_in_the_name");
  }

  return "";
};

export const getGroupNameErrors = (name: string, t: (key: string) => string) => {
  const pattern = /^[a-zA-Z0-9\-\'\s]+$/;

  if (name.trim() === "") {
    return t("Hospital_name_is_required");
  }

  if (!pattern.test(name)) {
    return t("Invalid_characters_in_the_hospital_name");
  }

  return "";
};

export const getHQNameErrors = (name: string, t: (key: string) => string) => {
  const pattern = /^[a-zA-Z0-9\-\'\s]+$/;

  if (name.trim() === "") {
    return t("Headquarter_name_is_required");
  }

  if (!pattern.test(name)) {
    return t("Invalid_characters_in_the_headquarter_name");
  }

  return "";
};

export const isEmailValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const getPasswordErrors = (
  password: string,
  confirmPassword: string, t: (key: string) => string
) => {
  if (password.length < 6) {
    return t("Password_should_be_at_least_6_characters_long");
  }
  if (password.length > 4096) {
    return t("Password_should_not_exceed_4096_characters");
  }
  if (!/\d/.test(password)) {
    return t("Password_should_contain_numbers");
  }
  if (password !== confirmPassword) {
    return t("Those_passwords_didnt_match_Try_again");
  }
  return "";
};

export const getFirstNameErrors = (firstName: string, t: (key: string) => string) => {
  const pattern = /^[a-zA-Z0-9\-\'\s]+$/;

  if (firstName.trim() === "") {
    return t("First_name_is_required");
  }

  if (!pattern.test(firstName)) {
    return t("Invalid_characters_in_the_first_name");
  }

  return "";
};

export const getLastNameErrors = (lastName: string, t: (key: string) => string) => {
  const pattern = /^[a-zA-Z0-9\-\'\s]+$/;

  if (lastName.trim() === "") {
    return t("Last_name_is_required");
  }

  if (!pattern.test(lastName)) {
    return t("Invalid_characters_in_the_last_name");
  }

  return "";
};

export const correctUserRoleName = (userRole: string, t: (key: string) => string): string => {
  switch (userRole) {
    case "admin":
      return t("Global_Administrator");
    case "headquarterAdmin":
      return t("Headquarter_Administrator");
    case "groupAdmin":
      return t("Hospital_Administrator");
    default:
      return userRole;
  }
};

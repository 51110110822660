import { User } from "../helpers/interfaces.tsx";
import { API_BASE_URL } from "../../config.tsx";
import { useTranslation } from "react-i18next";

type ConfirmDeleteUserModalProps = {
  setShowConfirmDeleteUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  user: User;
  handleDeleteUser: () => void;
};

const ConfirmDeleteUserModal: React.FC<ConfirmDeleteUserModalProps> = ({
  setShowConfirmDeleteUserModal,
  user,
  handleDeleteUser,
}) => {
  const { t, i18n } = useTranslation();

  const deleteUser = async (userId: string | null) => {
    try {
      const response = await fetch(`${API_BASE_URL}/deleteUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete user");
      }

      const userData = await response.json();
      return userData;
    } catch (error) {
      console.error("Error delete user:", error);
      throw new Error("An error occurred while delete user");
    }
  };

  const handleDeleteUserClick = async (userId: string | null) => {
    await deleteUser(userId);
    handleDeleteUser();
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div
          style={{ textAlign: "center", fontSize: "1.3em", marginTop: "2vh" }}
        >
          {t("Confirm_to_delete_user")}
        </div>
        <label style={{ lineHeight: "1", marginTop: "2vh" }}>
          {t("This_is_irreversible_action.")}
        </label>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5vh",
            maxHeight: "1%",
          }}
        >
          <img
            src="/images/Icon_User_Simple.png"
            style={{
              width: "3vh",
              minWidth: "15px",
              marginRight: "1vh",
            }}
          />
          <div className="userDataWrapper">
            <div className="userDataName">
              {user.firstName + " " + user.lastName}
            </div>
            <div className="userDataEmail">{user.email}</div>
          </div>
        </div>

        <div className="button-container">
          <button
            type="button"
            className="buttonCancel btn btn-primary"
            onClick={() => setShowConfirmDeleteUserModal(false)}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            className="buttonAccept btn btn-primary"
            onClick={() => handleDeleteUserClick(user.id)}
          >
            {t("Delete_User")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteUserModal;

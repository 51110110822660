import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../firebase/auth.tsx";
import { API_BASE_URL } from "../config.tsx";
import "../App.css";
import styles from "../styles/groupList.module.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import ConfirmDeleteGroupModal from "./modals/confirmDeleteGroupModal.tsx";
import ChangeGroupDataModal from "./modals/changeGroupDataModal.tsx";
import CreateAccountModal from "./modals/createAccountModal.tsx";
import ConfirmPermissionModal from "./modals/confirmPermissionModal.tsx";
import { getGroupsInHeadquarter } from "./helpers/requests.tsx";

import { useTranslation } from "react-i18next";

interface SelectedTraining {
  id?: string;
  name?: string;
  entityId?: string;
  permission?: boolean;
  manufacturerId?: string;
  isHidden?: boolean;
}

const GroupList = ({ groups, setGroups }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { userId, userRole } = useAuth();
  const [trainingsList, setTrainingsList] = useState<any[]>([]);
  const [comingSoonManufacturers, setComingSoonManufacturers] = useState<any[]>(
    []
  );
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [isLoadingTrainings, setIsLoadingTrainings] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [selectedGroupCountryId, setSelectedGroupCountryId] = useState("");
  const [selectedGroupCountryCode, setSelectedGroupCountryCode] = useState("");
  const [editingGroups, setEditingGroups] = useState<{
    [key: string]: boolean;
  }>({});
  const [showChangeDataModal, setShowChangeDataModal] = useState(false);

  const navigate = useNavigate();
  const { headquarterId, headquarterName } = useParams();

  const [showCreateUserAccountModal, setShowCreateUserAccountModal] =
    useState(false);
  const [showConfirmDeleteGroupModal, setShowConfirmDeleteGroupModal] =
    useState(false);
  const [showConfirmPermissionModal, setShowConfirmPermissionModal] =
    useState(false);
  const [selectedTraining, setSelectedTraining] = useState<SelectedTraining>(
    {}
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingGroups(true);

      const headquarterGroups = await getGroupsInHeadquarter(headquarterId);
      setGroups(headquarterGroups);

      setIsLoadingGroups(false);
    };

    fetchData();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);

      setGroups([]);
      setSelectedGroupId("");
      setSelectedGroupName("");
      setSelectedGroupCountryId("");
      setSelectedGroupCountryCode("");
      setEditingGroups({});
      setTrainingsList([]);
    };
  }, [setGroups]);

  const fetchGroupTrainingsList = async (groupId: string) => {
    setIsLoadingTrainings(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/getTrainingsListWithPermissions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            entityId: groupId,
            entityType: "group",
            showAll: false,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get group trainings list");
      }

      const trainings = await response.json();

      setTrainingsList(trainings);
    } catch (error) {
      console.error("Error get group trainings list:", error);
      throw new Error("An error occurred while get group trainings list");
    } finally {
      setIsLoadingTrainings(false);
    }
  };

  const fetchComingSoonManufacturers = async (groupId: string) => {
    setIsLoadingTrainings(true);

    try {
      const response = await fetch(
        `${API_BASE_URL}/getComingSoonManufacturers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            entityId: groupId,
            entityType: "group",
            showAll: false,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get coming soon manufacturers");
      }

      const manufacturers = await response.json();

      setComingSoonManufacturers(manufacturers);
    } catch (error) {
      console.error("Error get coming soon manufacturers:", error);
      throw new Error("An error occurred while get coming soon manufacturers");
    } finally {
      setIsLoadingTrainings(false);
    }
  };

  const updateGroupPermission = async (
    groupId: string,
    trainingId: string,
    isChecked: boolean
  ) => {
    try {
      const response = await fetch(`${API_BASE_URL}/updateTrainingPermission`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entityId: groupId,
          entityType: "group",
          trainingId,
          isChecked,
          isHidden: false,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update group training permission");
      }
    } catch (error) {
      console.error("Error update group training permission:", error);
      throw new Error(
        "An error occurred while update group training permission"
      );
    }
  };

  const toggleDropdown = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const renderCreateUserAccountModal = () => {
    return (
      <CreateAccountModal
        setShowCreateAccountModal={setShowCreateUserAccountModal}
        groupId={selectedGroupId}
        headquarterId={headquarterId}
        userRole={"user"}
        groupView={true}
        handleCreateAccount={handleCreateUserAccount}
      />
    );
  };

  const renderChangeDataModal = () => {
    return (
      <ChangeGroupDataModal
        setShowChangeDataModal={setShowChangeDataModal}
        groupId={selectedGroupId}
        groupName={selectedGroupName}
        groupCountryId={selectedGroupCountryId}
        groupCountryCode={selectedGroupCountryCode}
        headquarterId={headquarterId}
        handleChangeData={handleChangeData}
      />
    );
  };

  const renderConfirmDeleteGroupModal = () => {
    return (
      <ConfirmDeleteGroupModal
        setShowConfirmDeleteGroupModal={setShowConfirmDeleteGroupModal}
        groupId={selectedGroupId}
        groupName={selectedGroupName}
        groupCountryCode={selectedGroupCountryCode}
        handleDeleteGroupSubmit={handleDeleteGroupSubmit}
      />
    );
  };

  const handleCreateUserAccount = (newAccount: any[]) => {};

  const handleDeleteGroupSubmit = async () => {
    setGroups((prevGroups) =>
      prevGroups.filter((group) => group.id !== selectedGroupId)
    );
    setSelectedGroupId("");
    setSelectedGroupName("");
    setSelectedGroupCountryId("");
    setSelectedGroupCountryCode("");
    setEditingGroups({});
    setTrainingsList([]);
  };

  const handleChangeData = async (
    newGroupName: string,
    newGroupCountryId: string,
    newGroupCountryCode: string
  ) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) => {
        if (group.id === selectedGroupId) {
          return {
            ...group,
            name: newGroupName,
            countryCode: newGroupCountryCode,
          };
        }
        return group;
      })
    );

    // Update permissions rendering
    handleEditClick(
      selectedGroupId,
      newGroupName,
      newGroupCountryId,
      newGroupCountryCode
    );

    setShowChangeDataModal(false);

    setSelectedGroupName(newGroupName);
    setSelectedGroupCountryId(newGroupCountryId);
    setSelectedGroupCountryCode(newGroupCountryCode);
  };

  const handleViewClick = async (groupId: string) => {
    setIsLoadingGroups(true);
    navigate(`/userList/${headquarterId}/${groupId}`);
  };

  const handleEditClick = async (
    groupId: string,
    groupName: string,
    groupCountryId: string,
    groupCountryCode: string
  ) => {
    setEditingGroups((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      newState[groupId] = true;
      return newState;
    });

    setSelectedGroupId(groupId);
    setSelectedGroupName(groupName);
    setSelectedGroupCountryId(groupCountryId);
    setSelectedGroupCountryCode(groupCountryCode);

    await fetchGroupTrainingsList(groupId);
    await fetchComingSoonManufacturers(groupId);
  };

  const handlePermissionChange = async (
    trainingId: string,
    targetId: string,
    permission: boolean
  ) => {
    await updateGroupPermission(targetId, trainingId, permission);
    setTrainingsList(
      trainingsList.map((training) => {
        if (training.id === trainingId) {
          return { ...training, permission: permission };
        }

        return training;
      })
    );
    setSelectedTraining({});
  };

  const renderConfirmPermissionModal = () => {
    return (
      <ConfirmPermissionModal
        setShowConfirmPermissionModal={setShowConfirmPermissionModal}
        trainingId={selectedTraining.id}
        trainingName={selectedTraining.name}
        targetId={selectedTraining.entityId}
        targetName={selectedGroupName}
        permission={selectedTraining.permission}
        handlePermissionChange={handlePermissionChange}
      />
    );
  };

  const groupTrainingsByManufacturer = (trainings) => {
    const groupedTrainings = {};
    trainings.forEach((training) => {
      const {
        id,
        name,
        manufacturerId,
        manufacturerName,
        permission,
        entityId,
        isHidden,
      } = training;
      if (!groupedTrainings[manufacturerId]) {
        groupedTrainings[manufacturerId] = {
          manufacturerName: manufacturerName,
          trainings: [],
        };
      }
      groupedTrainings[manufacturerId].trainings.push({
        id: id,
        name: name,
        permission: permission,
        entityId: entityId,
        isHidden: isHidden,
        manufacturerName: manufacturerName,
      });
    });

    return groupedTrainings;
  };

  const renderGroupedTrainings = (groupedTrainings: {
    [manufacturerId: string]: {
      manufacturerName: string;
      trainings: {
        id: string;
        entityId: string;
        name: string;
        permission: boolean;
        isHidden: boolean;
      }[];
    };
  }) => {
    return (
      <>
        {Object.entries(groupedTrainings).map(
          ([manufacturerId, { manufacturerName, trainings }]) => (
            <div key={manufacturerId} className={styles.trainingsGroup}>
              <label className={styles.trainingsGroupTitle}>
                {manufacturerName}
              </label>
              <ul>
                {trainings.map((training) => (
                  <li key={training.id} className="form-check custom-checkbox">
                    <label className="custom-checkbox-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="agreeCheckbox"
                        checked={training.permission}
                        onChange={(e) => {
                          setSelectedTraining((prevInfo) => ({
                            ...prevInfo,
                            id: training.id,
                            name: training.name,
                            entityId: training.entityId,
                            permission: training.permission,
                          }));
                          setShowConfirmPermissionModal(true);
                        }}
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">{training.name}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )
        )}
        {comingSoonManufacturers.length > 0 && (
          <label
            className={styles.trainingsGroupTitle}
            style={{ color: "#39a686" }}
          >
            {t("Coming_soon")}:
          </label>
        )}
        {comingSoonManufacturers.map(({ id, name, isHidden }) => {
          return (
            <div key={id} className={styles.trainingsGroup}>
              <label
                className={styles.trainingsGroupTitle}
                style={{ color: isHidden ? "gray" : "#212529" }}
              >
                {name}
              </label>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className={styles.adminContainer}>
        {isLoadingGroups ? (
          <p className={styles.loading}>{t("Loading...")}</p>
        ) : (
          <>
            {/* Left side */}
            <div className={styles.leftContainer}>
              {/* Separate table header - don't find other way to avoid overlaping header on scroll */}
              <div className={styles.tableHeader}>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t("Hospital")}</th>
                      <th scope="col"></th>
                      <th scope="col" className={styles.usersColumn}>
                        {t("Users")}
                      </th>
                      <th scope="col">{t("Country")}</th>
                      <th scope="col" className={styles.editButtonWrapper}></th>
                      <th scope="col" className={styles.editButtonWrapper}></th>
                    </tr>
                  </thead>
                </table>
              </div>

              {/* Table with restricted height and scrollbar */}
              <div className={styles.tableContent}>
                <div className={`container-fluid ${styles.containerFluid}`}>
                  <table className="table">
                    <tbody>
                      {groups.map((group: any, index: number) => (
                        <tr
                          key={group.id}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#D4E5E2" : "transparent",
                          }}
                        >
                          <td>{group.name}</td>
                          <td>
                            {!group.hasGroupAdmin && (
                              <span style={{ color: "#a63939" }}>
                                {t("No_hospital_admin")}
                              </span>
                            )}
                          </td>
                          <td className={styles.usersColumn}>
                            {group.groupUsers}
                          </td>
                          <td>{t("country_" + group.countryCode)}</td>
                          <td className={styles.editButtonWrapper}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => handleViewClick(group.id)}
                              style={{
                                backgroundColor: "#39A686",
                                borderColor: "#39A686",
                              }}
                            >
                              {t("View")}
                            </button>
                          </td>
                          <td className={styles.editButtonWrapper}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                handleEditClick(
                                  group.id,
                                  group.name,
                                  group.countryId,
                                  group.countryCode
                                )
                              }
                              style={{
                                backgroundColor: editingGroups[group.id]
                                  ? "#404040"
                                  : "#39A686",
                                borderColor: editingGroups[group.id]
                                  ? "#404040"
                                  : "#39A686",
                              }}
                            >
                              {t("Details")}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Right side */}
            <div className={styles.rightContainer}>
              {selectedGroupId ? (
                <>
                  <div className={styles.containerHeaderWrapper}>
                    <div className={styles.containerHeader}>
                      <img
                        src="/images/Icon_Hospital.png"
                        style={{
                          width: "5vh",
                          minWidth: "25px",
                          marginRight: "15px",
                        }}
                      />
                      <div className={styles.userDataWrapper}>
                        <div className={styles.userDataName}>
                          {selectedGroupName}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className="dropdown" ref={dropdownRef}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={toggleDropdown}
                          style={{
                            width: "13vh",
                            minWidth: "60px",
                            backgroundColor: "#39A686",
                            borderColor: "#39A686",
                            borderRadius: "10px",
                            fontSize: "1em",
                            fontFamily: "VirtualLabQuickSandLight",
                            fontWeight: "bold",
                            paddingRight: "30px",
                          }}
                        >
                          <span
                            style={{
                              marginRight: "1.8vh",
                            }}
                          >
                            &#9013;
                          </span>
                          {t("Options")}
                        </button>
                        {isOptionsOpen && (
                          <div className={styles.dropdownMenu}>
                            <button
                              type="button"
                              onClick={() => {
                                setIsOptionsOpen(false);
                                setShowChangeDataModal(true);
                              }}
                              className="btn"
                            >
                              <img src="/images/Icon_Edit.png" />
                              {t("Edit_Hospital")}
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                setIsOptionsOpen(false);
                                setShowCreateUserAccountModal(true);
                              }}
                              className="btn"
                            >
                              <img src="/images/Icon_AddUser.png" />
                              {t("Create_new_hospital_account")}
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                setIsOptionsOpen(false);
                                setShowConfirmDeleteGroupModal(true);
                              }}
                              className="btn"
                            >
                              <img src="/images/Icon_Delete.png" />
                              {t("Delete_Hospital")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <hr />

                    <p className={styles.containerHeaderTitle}>
                      {t("Training_permission")}
                    </p>
                    <p className={styles.containerHeaderDescription}>
                      {t("Set_hospital_access_to_these_trainings_in_the_VR_application")}
                    </p>
                  </div>

                  <div
                    className={`${styles.trainingsContainer} thin-scrollbar`}
                  >
                    {isLoadingTrainings ? (
                      <p>{t("Loading...")}</p>
                    ) : (
                      renderGroupedTrainings(
                        groupTrainingsByManufacturer(trainingsList)
                      )
                    )}
                  </div>
                </>
              ) : (
                <p className={styles.selectUser}>{t("Select_hospital_to_edit")}</p>
              )}
            </div>
          </>
        )}
        <div className={styles.adminContainer}>
          {(showConfirmDeleteGroupModal && renderConfirmDeleteGroupModal()) ||
            (showCreateUserAccountModal && renderCreateUserAccountModal()) ||
            (showChangeDataModal && renderChangeDataModal()) ||
            (showConfirmPermissionModal && renderConfirmPermissionModal())}
        </div>
      </div>
    </>
  );
};
export default GroupList;
